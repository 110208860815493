<template>
  <div>
    <img
      :width="logo.width"
      :height="logo.height"
      :src="src"
      class="w-[120px] lg:w-[160px]"
      :alt="ngoName"
      :loading="lazy ? 'lazy' : 'eager'"
    >
  </div>
</template>

<script lang="ts" setup>
const { logo, ngoName } = getData().branding

const props = defineProps({
  lightMode: {
    type: Boolean,
    default: true,
  },
  color: {
    type: Boolean,
    default: true,
  },
  lazy: {
    type: Boolean,
    default: false,
  },
})

const src = computed(() => {
  if (props.color) {
    return props.lightMode ? logo.light : logo.dark
  }
  else {
    return props.lightMode ? logo.white : logo.black
  }
})
</script>
